// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { FillRow } from '../../../../platform/spectacle-base/common/types';

const data = [
  {
    "id": "solid",
    "name": "Solid",
    "props": {
      "assetId": "solid",
      "color1": [
        "solid"
      ],
      "defaults": {
        "color1": "white"
      }
    }
  },
  {
    "id": "nineties",
    "name": "90s",
    "props": {
      "assetId": "90s",
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "color3": [
        "c-1",
        "d-1"
      ],
      "defaults": {
        "color1": "divaBlue",
        "color2": "columbiaBlue",
        "color3": "royal"
      }
    }
  },
  {
    "id": "bio",
    "name": "Bio",
    "props": {
      "assetId": "bio",
      "color1": [
        "a-1"
      ],
      "color2": [
        "b-1"
      ],
      "color3": [
        "c-1"
      ],
      "defaults": {
        "color1": "kellyGreen",
        "color2": "forest",
        "color3": "brown"
      }
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<FillRow[]>> = data;

export type Fill = typeof typed[number];

export const FILL_INDEX_KEY = "id";
export type FillIndexKey = "id";
export type FillId = Fill["id"];

let i = 0;
export const FILL_DICT = {
  "solid": typed[i++],
  "nineties": typed[i++],
  "bio": typed[i++]
} as const;

export { typed as FILLS };
