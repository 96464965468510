import { createApp } from '../../../platform/spectacle-base/client/create-app'
import {
  VENDORS,
  PRODUCTS,
  FABRICS,
  DESIGNS,
  COLORS,
  COLOR_DICT,
  DESIGN_PARTS,
  FILLS,
  FONTS,
  PLACEMENTS,
  FILL_DICT,
} from '../common/sheets'
import { PATTERN_PACKAGE } from '../consts'

export const { controlTree, store } = createApp({
  patternPackage: PATTERN_PACKAGE,
  vendors: VENDORS,
  products: PRODUCTS,
  fabrics: FABRICS,
  designs: DESIGNS,
  designParts: DESIGN_PARTS,
  colors: COLORS,
  colorDict: COLOR_DICT,
  fills: FILLS,
  fillDict: FILL_DICT,
  fonts: FONTS,
  customFonts: [],
  placements: PLACEMENTS,
})
